<template>
    <div class="past-projects">
        <p class="pp-text">{{pastProjects.title}}</p>
        <Carousel :settings="settings">
            <div
                class="pp-slider"
                v-for="(image,index) in pastProjects.eventImages"
                :key="index"
            >
                <div class="pp-images">
                    <video
                        :key="index"
                        class="project-video"
                        id="video-container"
                        autoplay
                        loop
                        muted
                    >
                        <source
                            :src="image.video"
                            type="video/mp4"
                        >
                    </video>
                    <p class="project-name">{{image.name}}</p> 
                    <div class="image-number">{{index+1}}</div>               
                </div>

            </div>
        </Carousel>
    </div>
</template>
<script>
import Carousel from 'componentsv2/Carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
export default {
    name: 'PastProjects',
    components: {
        Carousel,
    },
    props:{
        pastProjects:Object,
    },
    data(){
        return{
            
            settings: {
                arrows: true,
                prevArrow: false,
                dots: true,
                focusOnSelect: true,
                infinite: false,
                speed: 500,
                slidesToShow: 3,
                slidesToScroll: 1,
                touchThreshold: 5,
                responsive: [
                    {
                        breakpoint: 800,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            infinite: true,
                        },
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            infinite: true,
                        },
                    },
                ],
            },
        }
    },
    mounted(){
        // var video = document.getElementById('video-container');
        // video.play();
  
    },
    
}
</script>
<style lang="scss">
@import './PastProjects.scss';
</style>