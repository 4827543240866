<template>
     <div class="other-events">
            <p>{{otherEvents.title}}</p>
            <Carousel :settings="settings">
                <div
                    class="slider"
                    v-for="(image,index) in otherEvents.eventImages"
                    :key="index"
                >
                    <div class="event-images">
                        <img :src="image.image"  />                   
                    </div>

                </div>
            </Carousel>
        </div>
</template>
<script>
import Carousel from 'componentsv2/Carousel'
export default {
    name: 'OtherEvents',
    components: {
        Carousel,
    },
    props:{
        otherEvents:Object,
    },
    data(){
        return{
             settings: {
                arrows: true,
                prevArrow: false,
                focusOnSelect: true,
                infinite: false,
                speed: 500,
                slidesToShow: 4,
                slidesToScroll: 1,
                touchThreshold: 5,
                responsive: [
                    {
                        breakpoint: 1200,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1,
                            infinite: true,
                        },
                    },
                    {
                        breakpoint: 900,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            infinite: true,
                        },
                    },
                    {
                        breakpoint: 500,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            infinite: true,
                        },
                    },
                ],
            },
        }
    }
    
}
</script>
<style lang="scss">
@import './OtherEvents.scss';
</style>