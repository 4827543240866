<template>
       <div class="designs-from">
            <p>{{designsFrom.title}}</p>
            <div class="wrapper">
                <div class="container">
                    
                    <div v-for="(design,index) in designsFrom.topDesigns" :key="index" :id="`design_img_${index}`"  >
                        
                        <img :src="design.image" @click="viewScene(design.ehdid,design.sceneid)"/>
                        <div v-if="design.price" class="design-price">
                            <svg xmlns="http://www.w3.org/2000/svg" width="25.545" height="21.13" viewBox="0 0 25.545 21.13">
                                <path d="M127.1 88.891a8.863 8.863 0 0 1 2.073-2.57 6.069 6.069 0 0 1 4-1.278 6.705 6.705 0 0 1 6.7 6.7c0 4.391-2.847 6.558-6.628 9.436a53.459 53.459 0 0 0-5.7 4.808.635.635 0 0 1-.894 0 53.485 53.485 0 0 0-5.7-4.809c-3.78-2.877-6.628-5.047-6.628-9.435a6.707 6.707 0 0 1 6.7-6.7 6.069 6.069 0 0 1 4 1.278 8.894 8.894 0 0 1 2.073 2.57z" transform="translate(-114.332 -85.043)" style="fill:#ff6100;fill-rule:evenodd"/>
                            </svg>
                            <p>₹ {{convertToIndianNumberSystem(design.price)}}</p>

                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="find-style">
                <p class="find-style-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do</p>
                <Button
                    primary
                    name="Find Your Style"
                    :handleClick="startQuiz"
                />
            </div> -->
        </div>
</template>
<script>
//import Button from 'componentsv2/Button'
import ConvertToPrice from 'mixins/ConvertToPrice'
export default {
    name:'DesignsFrom',
    components:{
        //Button,
    },
    mixins: [ConvertToPrice],
    props:{
        designsFrom:Object,
    },
    data(){
        return{            
            // url: process.env.VUE_APP_QUIZ_BASE_URL,
        }
    },
    mounted(){
        this.addStyleClasses()
    },
    methods:{
        // startQuiz() {
        //     window.open(`${this.url}?startQuiz=true`)
        // },
        addStyleClasses(){
            document.getElementById(`design_img_0`).classList.add('vertical')
            document.getElementById(`design_img_1`).classList.add('horizontal')
            document.getElementById(`design_img_2`).classList.add('big')
        },
        viewScene(ehdid,sceneid) {
            console.log('HI')
            if (ehdid != null) {
                this.$emit('viewScene', {
                    scene_id: sceneid,
                    ehd_id: ehdid,
                })
            } else {
                console.log('ehd id is null')
            }
        },
    }
}
</script>
<style lang="scss" scoped>
@import './DesignsFrom.scss';
</style>